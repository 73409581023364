import React, { useState } from 'react';
import { conditionFields } from './RuleFields';
import * as C from '../../services/constants';
import deleteIcon from '../../resources/images/delete-icon.svg';

const ConditionCreator = ({
    ruleDomain,
    condition,
    onConditionChange,
    onDeleteCondition,
    disabled,
}) => {
    const domains =
        ruleDomain === 'CONSUMER' ? ['CONSUMER'] : ['CONSUMER', ruleDomain];
    const [domain, setDomain] = useState(condition.domain);
    const booleanAttributes = [
        'completeProfile',
        'isReferral',
        'isGovtVerified',
        'isUpcUploaded',
    ];

    const handleConditionChange = (e) => {
        const { name, value } = e.target;
        let updatedCondition = { ...condition };

        switch (domain) {
            case 'CONSUMER': {
                const isBooleanAttribute = booleanAttributes.includes(value)
                if (isBooleanAttribute) {
                    updatedCondition['attributeName'] = value
                    updatedCondition['operation'] = '==';
                    updatedCondition['attributeValue'] = 'true';
                } else if (
                    (name === 'attributeValue' &&
                        C.REGEX_RULE_EVENT_ATTRIBUTE.test(value)) ||
                    value === ''
                ) {
                    updatedCondition[name] = value;
                } else if (name === 'operation') {
                    updatedCondition[name] = value;
                } else if (name === 'attributeName') {
                    updatedCondition[name] = value;
                } else {
                    updatedCondition[name] = condition[name];
                }
                break;
            }
            case 'GAME':
            case 'ARTICLE':
            case 'VIDEO':
            case 'QUIZ':
            case 'RAFFLE': {
                if (C.REGEX_RULE_EVENT_ATTRIBUTE.test(value) || value === '') {
                    updatedCondition[name] = value;
                } else {
                    updatedCondition[name] = condition[name];
                }
                break;
            }
            default:
                updatedCondition[name] = value;
                break;
        }

        onConditionChange(updatedCondition);
    };
    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'attributeValue') {
            if (
                condition.attributeName === 'eventFrequency' &&
                !C.RULE_FREQUENCY_VALUES.includes(value)
            ) {
                let updatedCondition = { ...condition };
                updatedCondition[name] = '';
                alert(`Please enter a valid ${condition.attributeName}`);
                onConditionChange(updatedCondition);
            } else if (
                (condition.attributeName === 'points' ||
                    condition.attributeName === 'eventCount') &&
                isNaN(value)
            ) {
                let updatedCondition = { ...condition };
                updatedCondition[name] = '';
                alert(`Please enter a valid ${condition.attributeName}`);
                onConditionChange(updatedCondition);
            } else {
                let updatedCondition = { ...condition };
                updatedCondition[name] = value;
                onConditionChange(updatedCondition);
            }
        }
    };
    const getPlaceholder = () => {
        if (condition.attributeName === 'eventFrequency') {
            return 'e.g., DAILY';
        } else if (
            condition.attributeName === 'points' ||
            condition.attributeName === 'eventCount'
        ) {
            return 'Enter a number';
        }
        return '';
    };

    const handleDomainChange = (e) => {
        const selectedDomain = e.target.value;
        const selectedDomainFields = conditionFields[selectedDomain];
        const defaultValues = {};

        selectedDomainFields.forEach((field) => {
            if (field.type === 'dropdown') {
                defaultValues[field.name] = field.values[0] || '';
            } else {
                defaultValues[field.name] = '';
            }
        });
        setDomain(selectedDomain);
        onConditionChange({
            ...condition,
            domain: selectedDomain,
            ...defaultValues,
        });
    };

    return (
        <>
            <div className="dropdown-container">
                <h4>Select Operator:</h4>
                <select
                    name="join"
                    onChange={(e) => handleConditionChange(e)}
                    value={condition.join}
                    disabled={disabled}
                >
                    <option value="&&">AND</option>
                    {/* <option value="||" disabled>OR</option> */}
                </select>
            </div>
            <div className="condition-dropdown-container">
                <div className="rule-domain">
                    <h4>Rule Applies To: </h4>
                    <select
                        name="domain"
                        onChange={(e) => {
                            handleDomainChange(e);
                        }}
                        value={condition.domain}
                        disabled={disabled}
                    >
                        {domains.map((domain) => (
                            <option key={domain} value={domain}>
                                {domain}
                            </option>
                        ))}
                    </select>
                </div>
                {conditionFields[condition.domain].map((field) => {
                    if (field.type === 'dropdown') {
                        // explicitly setting the operators for eventCount
                        if (
                            booleanAttributes.includes(
                                condition.attributeName
                            ) &&
                            field.name == 'operation'
                        ) {
                            return;
                        }
                        const values =
                            condition.attributeName === 'eventCount' &&
                            field.name === 'operation'
                                ? ['>=']
                                : field.values;
                        return (
                            <div key={field.name}>
                                <h4>{field.label}</h4>
                                <select
                                    name={field.name}
                                    onChange={(e) => handleConditionChange(e)}
                                    value={condition[field.name]}
                                    disabled={disabled}
                                    onBlur={(e) => handleBlur(e)}
                                >
                                    {values.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        );
                    }
                    if (field.type === 'text') {
                        if (
                            booleanAttributes.includes(
                                condition.attributeName
                            ) &&
                            field.name == 'attributeValue'
                        ) {
                            return;
                        }
                        return (
                            <div key={field.name}>
                                <h4>{field.label}</h4>
                                <input
                                    name={field.name}
                                    type="text"
                                    onChange={(e) => handleConditionChange(e)}
                                    value={condition[field.name]}
                                    disabled={disabled}
                                    onBlur={(e) => handleBlur(e)}
                                    placeholder={getPlaceholder()}
                                />
                            </div>
                        );
                    }
                })}
                {!disabled && (
                    <img src={deleteIcon} onClick={onDeleteCondition} />
                )}
            </div>
        </>
    );
};

export default ConditionCreator;
